import { Form, Formik } from 'formik';
import { useContext } from 'react';

import Button from '../../../../common/base/Button';
import Divider from '../../../../common/base/Divider';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { UserContext } from '../../../../contexts/UserContext';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';
import AccountAuthentication from './AccountAuthentication';
import SwitchInput from '../../../../common/Form/Fields/SwitchInput';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import { DashboardContext } from '../../DashboardContext';
import { useDialog } from '../../../../common/Dialog';
import Tooltip from '../../../../common/base/Tooltip';
import { isFreePlan } from '../../../../../utils/subscription';

const AccountProfile: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user, mutateUser } = useContext(UserContext);
  const { addToast } = useToasts();
  const { subscription, organizations, logout } = useContext(DashboardContext);
  const showDialog = useDialog();

  const user_owns_paid_orgs = !!organizations?.some(
    (org) =>
      org?.plan &&
      !isFreePlan(org?.plan) &&
      org?.role === 'owner' &&
      (!subscription?.cancel_at || new Date(subscription.cancel_at).getTime() > Date.now()),
  );
  const user_is_deletable = !user?.workos_profile_id && !user_owns_paid_orgs;

  const onDelete = () =>
    showDialog(
      () => {
        return HookdeckAPI.users
          .delete()
          .then(() => {
            logout();
          })
          .catch((e) => {
            const error_message =
              e?.response?.message || 'The account could not be deleted, please contact us.';
            addToast('error', error_message);
          });
      },
      undefined,
      {
        title: 'Delete Account',
        submit_label: 'Delete account',
        cancel_label: 'Cancel',
        submit_icon: 'delete',
        danger: true,
        form_props: {
          initial_values: {
            name: '',
          },
          validate: ({ name }: { name: string }) => {
            if (!name || name.length < 0) return { name: 'Required' };
            if (name !== user?.email) {
              return { name: 'Account email does not match.' };
            }
            return {};
          },
          Fields: () => (
            <>
              <Text m={{ b: 4 }}>
                This action can't be undone. If you delete your account, any organizations that you
                are the only member of will also be deleted. You'll lose your entire billing
                history, as well as the projects within those organizations and all of their
                associated data. Are you sure?
                <br />
                <br />
                If you’d like to delete this account, type <strong>{user?.email}</strong> below to
                confirm.
              </Text>
              <TextInput m={0} name="name" placeholder={user?.email} required />
            </>
          ),
        },
      },
    );

  const handleSubmit = (promise) => {
    return promise
      .then((user) => {
        mutateUser && mutateUser(user);
        addToast('success', 'Profile updated');
      })
      .catch(() => {
        addToast('error', 'Failed to update profile');
      });
  };

  return (
    <>
      <Div m={{ b: 14 }}>
        <Formik
          initialValues={{
            name: user!.name,
            email: user!.email,
          }}
          validate={(values: { name?: string; email?: string }) => {
            const errors: { name?: string; email?: string } = {};
            if (!values.name || values.name.length === 0) {
              errors.name = 'Required';
            }
            return errors;
          }}
          onSubmit={(values) =>
            handleSubmit(
              HookdeckAPI.session.updateMe({
                name: values.name,
              }),
            )
          }>
          {(props) => (
            <Form>
              <Text heading as="h2" m={{ b: 2 }}>
                Personal Details
              </Text>
              <Divider m={{ b: 4 }} />
              <TextInput label="Name" name="name" required />
              <TextInput
                label="Email"
                disabled
                name="email"
                type="email"
                help="Contact us to change your email"
              />
              <Button
                outline
                disabled={!props.isValid || !props.touched || props.isSubmitting}
                primary
                submit
                icon={props.isSubmitting ? 'loading' : 'save'}>
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </Div>
      <Div m={{ b: 14 }}>
        <Formik
          initialValues={{
            enable_alerts: !user!.disable_alerts,
          }}
          onSubmit={(values) => {
            return handleSubmit(
              HookdeckAPI.session.updateMe({
                disable_alerts: !values.enable_alerts,
              }),
            );
          }}>
          {(props) => (
            <Form>
              <Text heading as="h2" m={{ b: 2 }}>
                Notification Settings
              </Text>
              <Divider m={{ b: 4 }} />
              <StyledCard p={4} flex={{ justify: 'space-between' }} m={{ b: 4 }}>
                <div>
                  <Text>Email Notifications</Text>
                  <Text muted>Enable to receive platform updates via email.</Text>
                </div>
                <SwitchInput show_label name="enable_alerts" />
              </StyledCard>
              <Button
                minimal
                outline
                disabled={!props.isValid || !props.touched || props.isSubmitting}
                primary
                submit
                icon={props.isSubmitting ? 'loading' : 'save'}>
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </Div>
      <AccountAuthentication />
      <StyledCard m={{ b: 14 }}>
        <StyledCardSection p={{ y: 3, x: 4 }}>
          <Text heading danger>
            Danger Zone
          </Text>
        </StyledCardSection>
        <StyledCardSection p={4} flex={{ justify: 'space-between', align: 'center' }}>
          <Div>
            <Text heading as="h2" m={0}>
              Delete Account
            </Text>
            <Text muted>Delete your account and all associated account data.</Text>
          </Div>
          <Tooltip
            disabled={user_is_deletable}
            tooltip="You're the owner of an organization with a paid plan. In order to delete your account, you'll first need to either downgrade the organization to a free plan, or transfer ownership of the organization to a member within it.">
            <Button
              m={{ l: 4 }}
              disabled={!user_is_deletable}
              danger
              icon="delete"
              onClick={onDelete}>
              Delete
            </Button>
          </Tooltip>
        </StyledCardSection>
      </StyledCard>
    </>
  );
};

export default AccountProfile;
